import React from "react"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import SignIn from "../components/SignIn/SignIn"
const EventConfirmation: React.FC = () => {
  return (
    <main className="w-full bg-lightBackground h-screen">
      <FaviconHeader />
      <SignIn />
    </main>
  )
}

export default EventConfirmation

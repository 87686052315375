import { Link } from "gatsby"
import React from "react"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"
import BlackLogoDome from "./../../images/blackLogoDome.svg"
import AppleIcon from "./../../images/appleIcon.svg"

const SignIn: React.FC = () => {
  return (
    <section className="flex flex-col justify-center items-center container h-full mx-auto">
      <article className="mb-7">
        <Link
          id="header"
          to="/"
          className={`flex items-center justify-center mr-2 space-x-2`}
        >
          <img
            src={BlackLogoDome}
            alt="Logo"
            style={{ width: "40px", height: "40px" }}
            className={""}
          />

          <TextTypography fontStyle={FontStyle.BOLD} className="text-3xl">
            DOME
          </TextTypography>
        </Link>
      </article>
      <article className="flex flex-col space-y-1 items-center mb-7">
        <TextTypography fontStyle={FontStyle.BOLD} className="text-4xl">
          DOWNLOAD
        </TextTypography>
        <TextTypography fontStyle={FontStyle.BOLD} className="text-4xl">
          OUR APP
        </TextTypography>
      </article>
      <article className="flex flex-col max-w-xl space-y-3">
        <TextTypography color={TextColor.SECONDARY} className="text-lg">
          Dome Events is an exclusive events calendar for verified Capitol Hill
          professionals and vetted event partners.
        </TextTypography>
        <TextTypography color={TextColor.SECONDARY} className="text-lg">
          Dome is the only free and civil platform to discover policy
          information…and it’s easy to use.
        </TextTypography>
      </article>
      <article className="mt-8">
        <a
          href={"https://apps.apple.com/us/app/dome-compass/id1626261818"}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
        >
          <img
            src={AppleIcon}
            alt="Logo"
            style={{ width: "200px" }}
            className={"mr-4"}
          />
        </a>
      </article>
    </section>
  )
}

export default SignIn
